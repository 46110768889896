import api from '@/services/api'

export default {
  actions: {
    async getQuestionariesAvaliables({ commit }, user) {
      try {
        const data = user
        delete data.photo
        const query = new URLSearchParams(data)
        const response = await api.get(`/quiz/avaliables?${query}`)
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async getQuiz({ commit }, data) {
      try {
        const { user } = data
        delete user.photo
        const query = new URLSearchParams(user)
        const response = await api.get(
          `/quiz/${data.uuid}/next-question?${query}`,
        )
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
    async saveAnswer({ commit }, data) {
      try {
        const response = await api.post(
          `/quiz/${data.uuid}/save-question`,
          data,
        )
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
  },
}
