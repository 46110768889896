<template>
  <div class="navbar navbar-light text-priamary border-top navbar-static p-1">
    <div class="container-fluid ps-1">
      <ul class="nav w-100 d-flex justify-content-between">
        <li class="nav-item">
          <router-link
            to="home"
            class="navbar-nav-link navbar-nav-link-icon rounded p-1"
          >
            <div class="d-flex flex-column text-center">
              <i class="ph-house"></i>
              <div><small>Início</small></div>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="profile"
            class="navbar-nav-link navbar-nav-link-icon rounded p-1"
          >
            <div class="d-flex flex-column text-center">
              <i class="ph-user"></i>
              <div><small>Perfil</small></div>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="achievements"
            class="navbar-nav-link navbar-nav-link-icon rounded p-1"
          >
            <div class="d-flex flex-column text-center">
              <i class="ph-shopping-cart"></i>
              <div><small>Loja</small></div>
            </div>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="rules"
            class="navbar-nav-link navbar-nav-link-icon rounded p-1"
          >
            <div class="d-flex flex-column text-center">
              <i class="ph ph-file-text"></i>
              <div><small>Regulamento</small></div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Navbar',

  components: {},

  data() {
    return {
      showAccountModal: false,
    }
  },

  methods: {},
}
</script>
