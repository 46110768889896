const BASE_URL = '/'
const AUTH = BASE_URL
const HOME = `${BASE_URL}home`
const RULES = `${BASE_URL}rules`
const PROFILE = `${BASE_URL}profile`
const ACHIEVEMENTS = `${BASE_URL}achievements`
const UNAUTHORIZED = '/unauthorized'
const GROUPS_DETAIL = '/quiz/:uuid'

export default {
  BASE_URL,
  AUTH,
  HOME,
  PROFILE,
  RULES,
  ACHIEVEMENTS,
  UNAUTHORIZED,
  GROUPS_DETAIL,
}
