import api from '@/services/api'

export default {
  actions: {
    async getRanking({ commit }, data) {
      try {
        const query = new URLSearchParams(data.user)
        const response = await api.get(`/quiz/points?${query}`)
        return response && response.data ? response.data : []
      } catch (error) {
        throw error
      }
    },
  },
}
