import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'
import Auth from '../views/auth/Auth.vue'

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    path: PATHS.HOME,
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.GROUPS_DETAIL,
    name: 'Quiz',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Quiz.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.RULES,
    name: 'Rules',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Rules.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.PROFILE,
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Profile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: PATHS.ACHIEVEMENTS,
    name: 'Achievements',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Achievements.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  base: process.env.VUE_APP_BASE_URL,
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  if (store.getters.isLoggedInMestresDoValor && to.name === 'Auth') {
    return { name: 'Home' }
  }
  if (to.meta.requiresAuth) {
    if (
      !store.getters.isLoggedInMestresDoValor &&
      !localStorage.getItem('tokenMestresDoValor')
    ) {
      return { name: 'Auth' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedInMestresDoValor && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})
export default router
