/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */

// Check localStorage on page load and set mathing theme/direction
// ------------------------------
;(function () {
  localStorage.getItem('theme') === 'dark' &&
    document.documentElement.setAttribute('data-color-theme', 'dark')
})()

// Setup module
// ------------------------------
const themeSwitcher = (function () {
  //
  // Theme
  //
  const layoutTheme = function () {
    const primaryTheme = 'light'
    const secondaryTheme = 'dark'
    const storageKey = 'theme'
    const colorscheme = document.getElementsByName('main-theme')
    const mql = window.matchMedia(`(prefers-color-scheme: ${primaryTheme})`)

    // Changes the active radiobutton
    function indicateTheme(mode) {
      for (let i = colorscheme.length; i--; ) {
        if (colorscheme[i].value === mode) {
          colorscheme[i].checked = true
          colorscheme[i]
            .closest('.list-group-item')
            .classList.add('bg-primary', 'bg-opacity-10', 'border-primary')
        } else {
          colorscheme[i]
            .closest('.list-group-item')
            .classList.remove('bg-primary', 'bg-opacity-10', 'border-primary')
        }
      }
    }

    // Turns alt stylesheet on/off
    function applyTheme(mode) {
      const st = document.documentElement
      if (mode === primaryTheme) {
        st.removeAttribute('data-color-theme')
      } else if (mode === secondaryTheme) {
        st.setAttribute('data-color-theme', 'dark')
      } else if (!mql.matches) {
        st.setAttribute('data-color-theme', 'dark')
      } else {
        st.removeAttribute('data-color-theme')
      }
    }

    // Handles the media query evaluation, so it expects a media query as parameter
    function autoTheme(e) {
      const current = localStorage.getItem(storageKey)
      let mode = primaryTheme
      let indicate = primaryTheme
      // User set preference has priority
      if (current != null) {
        mode = current
        indicate = current
      } else if (e != null && e.matches) {
        mode = primaryTheme
      }
      applyTheme(mode)
      indicateTheme(indicate)
      setTimeout(function () {
        document.documentElement.classList.remove('no-transitions')
      }, 100)
    }

    // Handles radiobutton clicks
    function setTheme(e) {
      const mode = e.target.value
      document.documentElement.classList.add('no-transitions')
      if (mode === primaryTheme) {
        localStorage.removeItem(storageKey)
      } else {
        localStorage.setItem(storageKey, mode)
      }
      // When the auto button was clicked the auto-switcher needs to kick in
      autoTheme(mql)
    }

    // Create an event listener for media query matches and run it immediately
    autoTheme(mql)
    mql.addListener(autoTheme)

    // Set up listeners for radio button clicks */
    for (let i = colorscheme.length; i--; ) {
      colorscheme[i].onchange = setTheme
    }
  }

  //
  // Return objects assigned to module
  //
  return {
    init() {
      layoutTheme()
    },
  }
})()

// Initialize module
// ------------------------------
document.addEventListener('DOMContentLoaded', function () {
  themeSwitcher.init()
})
